import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ICardInterface } from 'src/app/interface/ICardResponse.interface';
import { ICardSettings } from 'src/app/interface/ICardSettings.interface';
import { Card } from 'src/app/model/card.model';
import { User } from 'src/app/model/user.model';
import { AppSettings } from 'src/app/services/app-settiings/app-settings.service';
import { CardSettingsService } from 'src/app/services/card-settings/card-settings.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  innerWidth = window.innerWidth;
  innerHeight = window.innerHeight;
  @ViewChild('headerCanvas', { static: false }) canvasRef: ElementRef<HTMLCanvasElement>;
  menuOpen = false;
  private canvas: HTMLCanvasElement;
  private stage: any;
  private exportRoot: any;
  private fnStartAnimation: () => void;
  isChangingStatus: boolean = true;
  cardInformation!: ICardInterface;
  cardSettings: ICardSettings;

  constructor(private route: Router, public appSettings: AppSettings, private cardSettingsService: CardSettingsService) {
    this.cardInformation = JSON.parse(localStorage.getItem('cardInformation'));

    this.checkSettingsAndShowDialogs();
  }

  ngOnInit(): void {
    this.loadScript('https://code.createjs.com/1.0.0/createjs.min.js', () => {
      this.loadScript('assets/js/logo-happy-plus.js', () => {
        this.init();
      });
    });
  }

  ngAfterViewInit(): void {
    if (this.canvasRef && this.canvasRef.nativeElement instanceof HTMLCanvasElement) {
      this.canvas = this.canvasRef.nativeElement;
    } else {
      console.error('Error: canvasRef.nativeElement no es un HTMLCanvasElement.');
    }

    this.init();
  }

  loadScript(url: string, callback?: () => void) {
    const script = document.createElement('script');
    script.src = url;
    script.onload = callback || (() => {});
    document.body.appendChild(script);
  }

  init(): void {
    if (!this.canvas) {
      console.error('Elementos no encontrados en el DOM. Verifica los IDs de los elementos.');
      return;
    }

    const comp = (window as any).AdobeAn.getComposition('00F0797A25BA4375BEC7C4ED9EEFA2E2');
    const lib = comp.getLibrary();
    const loader = new createjs.LoadQueue(false);

    loader.addEventListener('fileload', (evt: any) => this.handleFileLoad(evt, comp));
    loader.addEventListener('complete', (evt: any) => this.handleComplete(evt, comp));

    loader.loadManifest(lib.properties.manifest);
  }

  handleFileLoad(evt: any, comp: any): void {
    const images = comp.getImages();
    if (evt && evt.item.type === 'image') {
      images[evt.item.id] = evt.result;
    }
  }

  handleComplete(evt: any, comp: any): void {
    const lib = comp.getLibrary();
    const ss = comp.getSpriteSheet();
    const queue = evt.target;
    const ssMetadata = lib.ssMetadata;

    for (let i = 0; i < ssMetadata.length; i++) {
      ss[ssMetadata[i].name] = new createjs.SpriteSheet({
        images: [queue.getResult(ssMetadata[i].name)],
        frames: ssMetadata[i].frames,
      });
    }

    this.exportRoot = new lib.logoH();

    // Asigna 'stage' al objeto 'window' para que sea accesible globalmente
    (window as any).stage = this.stage = new lib.Stage(this.canvas);

    this.fnStartAnimation = () => {
      this.stage.addChild(this.exportRoot);
      createjs.Ticker.framerate = lib.properties.fps;
      createjs.Ticker.addEventListener('tick', this.stage);
    };

    (window as any).AdobeAn.makeResponsive(false, 'both', false, 1, [this.canvas]);
    (window as any).AdobeAn.compositionLoaded(lib.properties.id);
    this.fnStartAnimation();
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  goToPortal(): void {
    const cardCodeCache: string | null = localStorage.getItem('card-code');
    const userCacheData: string | null = localStorage.getItem('cardInformation');

    if (cardCodeCache !== null) {
      this.route.navigate([`/portal/${cardCodeCache}`]);
    } else if (userCacheData !== null) {
      const lastCacheUser: { card: Card; enroll: User } = JSON.parse(userCacheData);

      this.route.navigate([`/portal/${lastCacheUser.card.accountNumber}`]);
    } else {
      this.route.navigate([`/portal/`]);
    }

    if (this.menuOpen) this.toggleMenu();
  }

  changePage(src: string): void {
    this.route.navigate([src]);
    if (this.menuOpen) this.toggleMenu();
  }

  changeToHistoricalPage(src: string, type: string): void {
    this.route.navigate([src], { queryParams: { type: type } });

    if (this.menuOpen) this.toggleMenu();
  }

  async checkSettingsAndShowDialogs() {
    this.cardSettings = await this.cardSettingsService.getLatestCardSettings(this.cardInformation.card.accountNumber);

    if (this.cardInformation.enroll != null && this.cardSettings === null) {
      // CASO 1: Usuario sin configuración previa
      // console.log(`Personalizando experiencia a ${this.cardInformation.enroll.email}`);
      const settings: ICardSettings = {
        account: this.cardInformation.card.accountNumber,
        showSpecialOffer: true,
      };

      this.cardSettings = await this.cardSettingsService.createNewSettings(settings);
    }

    this.isChangingStatus = false;
  }

  async saveUserSettings() {
    try {
      this.isChangingStatus = true;

      this.cardSettings.showSpecialOffer = !this.cardSettings.showSpecialOffer;

      await this.cardSettingsService.updateCardSettings(this.cardSettings);
    } catch (errCritical) {
      // console.error(errCritical);
    } finally {
      this.isChangingStatus = false;
    }
  }
}
