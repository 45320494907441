<mat-dialog-content>
  <div class="row position-relative">
    <div class="dialog-header">
      <span class="title">
        <section id="icon-and-message">
          <div class="d-flex" style="justify-content: center">
            <section id="icon">
              <img class="img-plus" [src]="'assets/images/icons/ic_ticket_discount.svg'" height="60vh" alt="Logo" />
            </section>
            <section id="comment">
              <p class="text-plus align-center" style="color: black; font-size: smaller">
                Hola, tenemos esta oferta<br />
                sólo para ti...
              </p>
            </section>
          </div>
        </section>
      </span>
      <button mat-icon-button class="close-button" (click)="dialogRef?.close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="row mt-1">
    <div *ngIf="this.isSearchingPromotions; then ShowSpinner; else ShowImage"></div>
    <ng-template #ShowSpinner>
      <div class="col-12">
        <div class="loading-data">
          <div class="loading-data-shade">
            <div class="row">
              <div class="col-12" style="display: flex; justify-content: center">
                <mat-spinner [diameter]="40"></mat-spinner>
              </div>
              <div class="col-12 mt-3" style="align-content: space-around; place-items: center">
                <h3>Cargando Ofertas</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #ShowImage>
      <div class="col-12">
        <div class="carousel-container">
          <div class="carousel">
            <div class="carousel-item">
              <img [src]="this.paymentImageURL" style="cursor: pointer" alt="Special Offer" (click)="requestRedeemOffer()" />
              <!-- <img [src]="'https://placehold.co/240x240'" style="cursor: pointer" alt="Special Offer" /> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3 mb-1 text-right">
        <p style="color: blue; cursor: pointer" (click)="viewSpecialOfferTerm()">> Términos legales</p>
      </div>
    </ng-template>
  </div>
  <div class="row justify-content-end mt-2">
    <div class="col-2">
      <mat-slide-toggle
        class="green-toggle"
        style="line-height: 1 !important"
        [disabled]="this.isChangingStatus"
        [checked]="this.cardSettings.showSpecialOffer"
        (toggleChange)="saveUserSettings()"
      >
      </mat-slide-toggle>
    </div>
    <div class="col-10">
      <span class="text-plus" (click)="saveUserSettings()" style="cursor: pointer">
        Si no quieres visualizar esta oferta<br />
        Selecciona aquí
      </span>
      <p class="m-0 text-plus">
        <small> ¡RECUERDA! También puedes activar o desactivar esta oferta en el menú superior de HAPPY+</small>
      </p>
    </div>
  </div>
</mat-dialog-content>
