/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import { Client, generateClient, GraphQLResult } from "aws-amplify/api";
import { Observable } from "rxjs";

export type __SubscriptionContainer = {
  onCreateCardSettings: OnCreateCardSettingsSubscription;
  onCreateOneClickUserLog: OnCreateOneClickUserLogSubscription;
  onCreatePromotions: OnCreatePromotionsSubscription;
  onCreateRepurchase: OnCreateRepurchaseSubscription;
  onCreateSegments: OnCreateSegmentsSubscription;
  onCreateTrackingUsers: OnCreateTrackingUsersSubscription;
  onCreateWebClientSettings: OnCreateWebClientSettingsSubscription;
  onDeleteCardSettings: OnDeleteCardSettingsSubscription;
  onDeleteOneClickUserLog: OnDeleteOneClickUserLogSubscription;
  onDeletePromotions: OnDeletePromotionsSubscription;
  onDeleteRepurchase: OnDeleteRepurchaseSubscription;
  onDeleteSegments: OnDeleteSegmentsSubscription;
  onDeleteTrackingUsers: OnDeleteTrackingUsersSubscription;
  onDeleteWebClientSettings: OnDeleteWebClientSettingsSubscription;
  onUpdateCardSettings: OnUpdateCardSettingsSubscription;
  onUpdateOneClickUserLog: OnUpdateOneClickUserLogSubscription;
  onUpdatePromotions: OnUpdatePromotionsSubscription;
  onUpdateRepurchase: OnUpdateRepurchaseSubscription;
  onUpdateSegments: OnUpdateSegmentsSubscription;
  onUpdateTrackingUsers: OnUpdateTrackingUsersSubscription;
  onUpdateWebClientSettings: OnUpdateWebClientSettingsSubscription;
};

export type CreateCardSettingsInput = {
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type CardSettings = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type CreateOneClickUserLogInput = {
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type OneClickUserLog = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type CreatePromotionsInput = {
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  isSegmentedOffer?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfAdminPromotion?: string | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type Promotions = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  isSegmentedOffer?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfAdminPromotion?: string | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type CreateRepurchaseInput = {
  created_at?: string | null;
  isLatest?: boolean | null;
  paymentImageURL?: string | null;
  promotion_snapshot?: string | null;
  repurchase_amount?: number | null;
  uid: string;
  updated_at?: string | null;
  waiverImageURL?: string | null;
};

export type Repurchase = {
  __typename: "Repurchase";
  created_at?: string | null;
  isLatest?: boolean | null;
  paymentImageURL?: string | null;
  promotion_snapshot?: string | null;
  repurchase_amount?: number | null;
  uid: string;
  updated_at?: string | null;
  waiverImageURL?: string | null;
};

export type CreateSegmentsInput = {
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  offerRepeatLimit?: string | null;
  offerShowTiming?: string | null;
  offerType?: string | null;
  onlyNewCards?: boolean | null;
  paymentImageURL?: string | null;
  promotionRef?: string | null;
  redirectToPromotion?: boolean | null;
  status?: boolean | null;
  uid: string;
  waiverImageURL?: string | null;
};

export type Segments = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  offerRepeatLimit?: string | null;
  offerShowTiming?: string | null;
  offerType?: string | null;
  onlyNewCards?: boolean | null;
  paymentImageURL?: string | null;
  promotionRef?: string | null;
  redirectToPromotion?: boolean | null;
  status?: boolean | null;
  uid: string;
  waiverImageURL?: string | null;
};

export type CreateTrackingUsersInput = {
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type TrackingUsers = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type CreateWebClientSettingsInput = {
  environment?: string | null;
  maxAccountTimeLimit?: number | null;
  minAccountTimeLimit?: number | null;
  uid: string;
};

export type WebClientSettings = {
  __typename: "WebClientSettings";
  environment?: string | null;
  maxAccountTimeLimit?: number | null;
  minAccountTimeLimit?: number | null;
  uid: string;
};

export type DeleteCardSettingsInput = {
  account: number;
};

export type DeleteOneClickUserLogInput = {
  uid: string;
};

export type DeletePromotionsInput = {
  uid: string;
};

export type DeleteRepurchaseInput = {
  uid: string;
};

export type DeleteSegmentsInput = {
  uid: string;
};

export type DeleteTrackingUsersInput = {
  uid: string;
};

export type DeleteWebClientSettingsInput = {
  uid: string;
};

export type UpdateCardSettingsInput = {
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type UpdateOneClickUserLogInput = {
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type UpdatePromotionsInput = {
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  isSegmentedOffer?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfAdminPromotion?: string | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type UpdateRepurchaseInput = {
  created_at?: string | null;
  isLatest?: boolean | null;
  paymentImageURL?: string | null;
  promotion_snapshot?: string | null;
  repurchase_amount?: number | null;
  uid: string;
  updated_at?: string | null;
  waiverImageURL?: string | null;
};

export type UpdateSegmentsInput = {
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  offerRepeatLimit?: string | null;
  offerShowTiming?: string | null;
  offerType?: string | null;
  onlyNewCards?: boolean | null;
  paymentImageURL?: string | null;
  promotionRef?: string | null;
  redirectToPromotion?: boolean | null;
  status?: boolean | null;
  uid: string;
  waiverImageURL?: string | null;
};

export type UpdateTrackingUsersInput = {
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type UpdateWebClientSettingsInput = {
  environment?: string | null;
  maxAccountTimeLimit?: number | null;
  minAccountTimeLimit?: number | null;
  uid: string;
};

export type TableCardSettingsFilterInput = {
  account?: TableIntFilterInput | null;
  segmentRef?: TableStringFilterInput | null;
  showSpecialOffer?: TableBooleanFilterInput | null;
};

export type TableIntFilterInput = {
  between?: Array<number | null> | null;
  contains?: number | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
  notContains?: number | null;
};

export type TableStringFilterInput = {
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
};

export type TableBooleanFilterInput = {
  eq?: boolean | null;
  ne?: boolean | null;
};

export type CardSettingsConnection = {
  __typename: "CardSettingsConnection";
  items?: Array<CardSettings | null> | null;
  nextToken?: string | null;
};

export type TableOneClickUserLogFilterInput = {
  authorizationCode?: TableIntFilterInput | null;
  cardNumber?: TableStringFilterInput | null;
  cardType?: TableStringFilterInput | null;
  email?: TableStringFilterInput | null;
  responseCode?: TableIntFilterInput | null;
  uid?: TableStringFilterInput | null;
  username?: TableStringFilterInput | null;
};

export type OneClickUserLogConnection = {
  __typename: "OneClickUserLogConnection";
  items?: Array<OneClickUserLog | null> | null;
  nextToken?: string | null;
};

export type TablePromotionsFilterInput = {
  amount?: TableIntFilterInput | null;
  basesDescription?: TableStringFilterInput | null;
  bonusAmount?: TableIntFilterInput | null;
  bonusToken?: TableIntFilterInput | null;
  color?: TableStringFilterInput | null;
  daysAvailable?: TableStringFilterInput | null;
  durationDescription?: TableStringFilterInput | null;
  enabled?: TableBooleanFilterInput | null;
  finalDate?: TableStringFilterInput | null;
  initialDate?: TableStringFilterInput | null;
  isDeleted?: TableBooleanFilterInput | null;
  isEnabledAllCustomers?: TableBooleanFilterInput | null;
  name?: TableStringFilterInput | null;
  paymentAmount?: TableIntFilterInput | null;
  segment_uid?: TableStringFilterInput | null;
  tickets?: TableIntFilterInput | null;
  token?: TableIntFilterInput | null;
  typeOfAdminPromotion?: TableStringFilterInput | null;
  typeOfPromotion?: TableStringFilterInput | null;
  uid?: TableIDFilterInput | null;
  updateTime?: TableStringFilterInput | null;
};

export type TableIDFilterInput = {
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
};

export type PromotionsConnection = {
  __typename: "PromotionsConnection";
  items?: Array<Promotions | null> | null;
  nextToken?: string | null;
};

export type TableRepurchaseFilterInput = {
  created_at?: TableStringFilterInput | null;
  isLatest?: TableBooleanFilterInput | null;
  paymentImageURL?: TableStringFilterInput | null;
  repurchase_amount?: TableIntFilterInput | null;
  uid?: TableStringFilterInput | null;
  updated_at?: TableStringFilterInput | null;
  waiverImageURL?: TableStringFilterInput | null;
};

export type RepurchaseConnection = {
  __typename: "RepurchaseConnection";
  items?: Array<Repurchase | null> | null;
  nextToken?: string | null;
};

export type TableSegmentsFilterInput = {
  creationDate?: TableStringFilterInput | null;
  isDeleted?: TableBooleanFilterInput | null;
  lastUpdate?: TableStringFilterInput | null;
  name?: TableStringFilterInput | null;
  offerRepeatLimit?: TableStringFilterInput | null;
  offerShowTiming?: TableStringFilterInput | null;
  offerType?: TableStringFilterInput | null;
  onlyNewCards?: TableBooleanFilterInput | null;
  paymentImageURL?: TableStringFilterInput | null;
  promotionRef?: TableStringFilterInput | null;
  redirectToPromotion?: TableBooleanFilterInput | null;
  status?: TableBooleanFilterInput | null;
  uid?: TableStringFilterInput | null;
  waiverImageURL?: TableStringFilterInput | null;
};

export type SegmentsConnection = {
  __typename: "SegmentsConnection";
  items?: Array<Segments | null> | null;
  nextToken?: string | null;
};

export type TableTrackingUsersFilterInput = {
  cardNumber?: TableFloatFilterInput | null;
  fidelity?: TableStringFilterInput | null;
  finalDate?: TableFloatFilterInput | null;
  initialDate?: TableFloatFilterInput | null;
  lastStepReached?: TableStringFilterInput | null;
  paymentAmount?: TableFloatFilterInput | null;
  paymentMethod?: TableStringFilterInput | null;
  promotionCash?: TableFloatFilterInput | null;
  promotionCashBonus?: TableFloatFilterInput | null;
  promotionTickets?: TableFloatFilterInput | null;
  promotionTokenBonus?: TableFloatFilterInput | null;
  promotionTokens?: TableFloatFilterInput | null;
  registeredEmail?: TableStringFilterInput | null;
  uid?: TableStringFilterInput | null;
};

export type TableFloatFilterInput = {
  between?: Array<number | null> | null;
  contains?: number | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
  notContains?: number | null;
};

export type TrackingUsersConnection = {
  __typename: "TrackingUsersConnection";
  items?: Array<TrackingUsers | null> | null;
  nextToken?: string | null;
};

export type TableWebClientSettingsFilterInput = {
  environment?: TableStringFilterInput | null;
  maxAccountTimeLimit?: TableIntFilterInput | null;
  minAccountTimeLimit?: TableIntFilterInput | null;
  uid?: TableStringFilterInput | null;
};

export type WebClientSettingsConnection = {
  __typename: "WebClientSettingsConnection";
  items?: Array<WebClientSettings | null> | null;
  nextToken?: string | null;
};

export type CreateCardSettingsMutation = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type CreateOneClickUserLogMutation = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type CreatePromotionsMutation = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  isSegmentedOffer?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfAdminPromotion?: string | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type CreateRepurchaseMutation = {
  __typename: "Repurchase";
  created_at?: string | null;
  isLatest?: boolean | null;
  paymentImageURL?: string | null;
  promotion_snapshot?: string | null;
  repurchase_amount?: number | null;
  uid: string;
  updated_at?: string | null;
  waiverImageURL?: string | null;
};

export type CreateSegmentsMutation = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  offerRepeatLimit?: string | null;
  offerShowTiming?: string | null;
  offerType?: string | null;
  onlyNewCards?: boolean | null;
  paymentImageURL?: string | null;
  promotionRef?: string | null;
  redirectToPromotion?: boolean | null;
  status?: boolean | null;
  uid: string;
  waiverImageURL?: string | null;
};

export type CreateTrackingUsersMutation = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type CreateWebClientSettingsMutation = {
  __typename: "WebClientSettings";
  environment?: string | null;
  maxAccountTimeLimit?: number | null;
  minAccountTimeLimit?: number | null;
  uid: string;
};

export type DeleteCardSettingsMutation = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type DeleteOneClickUserLogMutation = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type DeletePromotionsMutation = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  isSegmentedOffer?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfAdminPromotion?: string | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type DeleteRepurchaseMutation = {
  __typename: "Repurchase";
  created_at?: string | null;
  isLatest?: boolean | null;
  paymentImageURL?: string | null;
  promotion_snapshot?: string | null;
  repurchase_amount?: number | null;
  uid: string;
  updated_at?: string | null;
  waiverImageURL?: string | null;
};

export type DeleteSegmentsMutation = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  offerRepeatLimit?: string | null;
  offerShowTiming?: string | null;
  offerType?: string | null;
  onlyNewCards?: boolean | null;
  paymentImageURL?: string | null;
  promotionRef?: string | null;
  redirectToPromotion?: boolean | null;
  status?: boolean | null;
  uid: string;
  waiverImageURL?: string | null;
};

export type DeleteTrackingUsersMutation = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type DeleteWebClientSettingsMutation = {
  __typename: "WebClientSettings";
  environment?: string | null;
  maxAccountTimeLimit?: number | null;
  minAccountTimeLimit?: number | null;
  uid: string;
};

export type UpdateCardSettingsMutation = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type UpdateOneClickUserLogMutation = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type UpdatePromotionsMutation = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  isSegmentedOffer?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfAdminPromotion?: string | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type UpdateRepurchaseMutation = {
  __typename: "Repurchase";
  created_at?: string | null;
  isLatest?: boolean | null;
  paymentImageURL?: string | null;
  promotion_snapshot?: string | null;
  repurchase_amount?: number | null;
  uid: string;
  updated_at?: string | null;
  waiverImageURL?: string | null;
};

export type UpdateSegmentsMutation = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  offerRepeatLimit?: string | null;
  offerShowTiming?: string | null;
  offerType?: string | null;
  onlyNewCards?: boolean | null;
  paymentImageURL?: string | null;
  promotionRef?: string | null;
  redirectToPromotion?: boolean | null;
  status?: boolean | null;
  uid: string;
  waiverImageURL?: string | null;
};

export type UpdateTrackingUsersMutation = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type UpdateWebClientSettingsMutation = {
  __typename: "WebClientSettings";
  environment?: string | null;
  maxAccountTimeLimit?: number | null;
  minAccountTimeLimit?: number | null;
  uid: string;
};

export type GetCardSettingsQuery = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type GetOneClickUserLogQuery = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type GetPromotionsQuery = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  isSegmentedOffer?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfAdminPromotion?: string | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type GetRepurchaseQuery = {
  __typename: "Repurchase";
  created_at?: string | null;
  isLatest?: boolean | null;
  paymentImageURL?: string | null;
  promotion_snapshot?: string | null;
  repurchase_amount?: number | null;
  uid: string;
  updated_at?: string | null;
  waiverImageURL?: string | null;
};

export type GetSegmentsQuery = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  offerRepeatLimit?: string | null;
  offerShowTiming?: string | null;
  offerType?: string | null;
  onlyNewCards?: boolean | null;
  paymentImageURL?: string | null;
  promotionRef?: string | null;
  redirectToPromotion?: boolean | null;
  status?: boolean | null;
  uid: string;
  waiverImageURL?: string | null;
};

export type GetTrackingUsersQuery = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type GetWebClientSettingsQuery = {
  __typename: "WebClientSettings";
  environment?: string | null;
  maxAccountTimeLimit?: number | null;
  minAccountTimeLimit?: number | null;
  uid: string;
};

export type ListCardSettingsQuery = {
  __typename: "CardSettingsConnection";
  items?: Array<{
    __typename: "CardSettings";
    account: number;
    segmentRef?: string | null;
    showSpecialOffer?: boolean | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListOneClickUserLogsQuery = {
  __typename: "OneClickUserLogConnection";
  items?: Array<{
    __typename: "OneClickUserLog";
    authorizationCode?: number | null;
    cardNumber?: string | null;
    cardType?: string | null;
    email?: string | null;
    responseCode?: number | null;
    uid: string;
    username?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListPromotionsQuery = {
  __typename: "PromotionsConnection";
  items?: Array<{
    __typename: "Promotions";
    amount?: number | null;
    basesDescription?: string | null;
    bonusAmount?: number | null;
    bonusToken?: number | null;
    color?: string | null;
    daysAvailable?: Array<string | null> | null;
    durationDescription?: string | null;
    enabled?: boolean | null;
    finalDate?: string | null;
    initialDate?: string | null;
    isDeleted?: boolean | null;
    isEnabledAllCustomers?: boolean | null;
    isSegmentedOffer?: boolean | null;
    name?: string | null;
    paymentAmount?: number | null;
    segment_uid?: string | null;
    tickets?: number | null;
    token?: number | null;
    typeOfAdminPromotion?: string | null;
    typeOfPromotion?: string | null;
    uid: string;
    updateTime?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListRepurchasesQuery = {
  __typename: "RepurchaseConnection";
  items?: Array<{
    __typename: "Repurchase";
    created_at?: string | null;
    isLatest?: boolean | null;
    paymentImageURL?: string | null;
    promotion_snapshot?: string | null;
    repurchase_amount?: number | null;
    uid: string;
    updated_at?: string | null;
    waiverImageURL?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListSegmentsQuery = {
  __typename: "SegmentsConnection";
  items?: Array<{
    __typename: "Segments";
    creationDate?: string | null;
    isDeleted?: boolean | null;
    lastUpdate?: string | null;
    name?: string | null;
    offerRepeatLimit?: string | null;
    offerShowTiming?: string | null;
    offerType?: string | null;
    onlyNewCards?: boolean | null;
    paymentImageURL?: string | null;
    promotionRef?: string | null;
    redirectToPromotion?: boolean | null;
    status?: boolean | null;
    uid: string;
    waiverImageURL?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListTrackingUsersQuery = {
  __typename: "TrackingUsersConnection";
  items?: Array<{
    __typename: "TrackingUsers";
    cardNumber?: number | null;
    fidelity?: string | null;
    finalDate?: number | null;
    initialDate?: number | null;
    lastStepReached?: string | null;
    paymentAmount?: number | null;
    paymentMethod?: string | null;
    promotionCash?: number | null;
    promotionCashBonus?: number | null;
    promotionTickets?: number | null;
    promotionTokenBonus?: number | null;
    promotionTokens?: number | null;
    registeredEmail?: string | null;
    uid: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListWebClientSettingsQuery = {
  __typename: "WebClientSettingsConnection";
  items?: Array<{
    __typename: "WebClientSettings";
    environment?: string | null;
    maxAccountTimeLimit?: number | null;
    minAccountTimeLimit?: number | null;
    uid: string;
  } | null> | null;
  nextToken?: string | null;
};

export type OnCreateCardSettingsSubscription = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type OnCreateOneClickUserLogSubscription = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type OnCreatePromotionsSubscription = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  isSegmentedOffer?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfAdminPromotion?: string | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type OnCreateRepurchaseSubscription = {
  __typename: "Repurchase";
  created_at?: string | null;
  isLatest?: boolean | null;
  paymentImageURL?: string | null;
  promotion_snapshot?: string | null;
  repurchase_amount?: number | null;
  uid: string;
  updated_at?: string | null;
  waiverImageURL?: string | null;
};

export type OnCreateSegmentsSubscription = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  offerRepeatLimit?: string | null;
  offerShowTiming?: string | null;
  offerType?: string | null;
  onlyNewCards?: boolean | null;
  paymentImageURL?: string | null;
  promotionRef?: string | null;
  redirectToPromotion?: boolean | null;
  status?: boolean | null;
  uid: string;
  waiverImageURL?: string | null;
};

export type OnCreateTrackingUsersSubscription = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type OnCreateWebClientSettingsSubscription = {
  __typename: "WebClientSettings";
  environment?: string | null;
  maxAccountTimeLimit?: number | null;
  minAccountTimeLimit?: number | null;
  uid: string;
};

export type OnDeleteCardSettingsSubscription = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type OnDeleteOneClickUserLogSubscription = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type OnDeletePromotionsSubscription = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  isSegmentedOffer?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfAdminPromotion?: string | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type OnDeleteRepurchaseSubscription = {
  __typename: "Repurchase";
  created_at?: string | null;
  isLatest?: boolean | null;
  paymentImageURL?: string | null;
  promotion_snapshot?: string | null;
  repurchase_amount?: number | null;
  uid: string;
  updated_at?: string | null;
  waiverImageURL?: string | null;
};

export type OnDeleteSegmentsSubscription = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  offerRepeatLimit?: string | null;
  offerShowTiming?: string | null;
  offerType?: string | null;
  onlyNewCards?: boolean | null;
  paymentImageURL?: string | null;
  promotionRef?: string | null;
  redirectToPromotion?: boolean | null;
  status?: boolean | null;
  uid: string;
  waiverImageURL?: string | null;
};

export type OnDeleteTrackingUsersSubscription = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type OnDeleteWebClientSettingsSubscription = {
  __typename: "WebClientSettings";
  environment?: string | null;
  maxAccountTimeLimit?: number | null;
  minAccountTimeLimit?: number | null;
  uid: string;
};

export type OnUpdateCardSettingsSubscription = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type OnUpdateOneClickUserLogSubscription = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type OnUpdatePromotionsSubscription = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  isSegmentedOffer?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfAdminPromotion?: string | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type OnUpdateRepurchaseSubscription = {
  __typename: "Repurchase";
  created_at?: string | null;
  isLatest?: boolean | null;
  paymentImageURL?: string | null;
  promotion_snapshot?: string | null;
  repurchase_amount?: number | null;
  uid: string;
  updated_at?: string | null;
  waiverImageURL?: string | null;
};

export type OnUpdateSegmentsSubscription = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  offerRepeatLimit?: string | null;
  offerShowTiming?: string | null;
  offerType?: string | null;
  onlyNewCards?: boolean | null;
  paymentImageURL?: string | null;
  promotionRef?: string | null;
  redirectToPromotion?: boolean | null;
  status?: boolean | null;
  uid: string;
  waiverImageURL?: string | null;
};

export type OnUpdateTrackingUsersSubscription = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type OnUpdateWebClientSettingsSubscription = {
  __typename: "WebClientSettings";
  environment?: string | null;
  maxAccountTimeLimit?: number | null;
  minAccountTimeLimit?: number | null;
  uid: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  public client: Client;
  constructor() {
    this.client = generateClient();
  }
  async CreateCardSettings(
    input: CreateCardSettingsInput
  ): Promise<CreateCardSettingsMutation> {
    const statement = `mutation CreateCardSettings($input: CreateCardSettingsInput!) {
        createCardSettings(input: $input) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateCardSettingsMutation>response.data.createCardSettings;
  }
  async CreateOneClickUserLog(
    input: CreateOneClickUserLogInput
  ): Promise<CreateOneClickUserLogMutation> {
    const statement = `mutation CreateOneClickUserLog($input: CreateOneClickUserLogInput!) {
        createOneClickUserLog(input: $input) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateOneClickUserLogMutation>response.data.createOneClickUserLog;
  }
  async CreatePromotions(
    input: CreatePromotionsInput
  ): Promise<CreatePromotionsMutation> {
    const statement = `mutation CreatePromotions($input: CreatePromotionsInput!) {
        createPromotions(input: $input) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          isSegmentedOffer
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfAdminPromotion
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreatePromotionsMutation>response.data.createPromotions;
  }
  async CreateRepurchase(
    input: CreateRepurchaseInput
  ): Promise<CreateRepurchaseMutation> {
    const statement = `mutation CreateRepurchase($input: CreateRepurchaseInput!) {
        createRepurchase(input: $input) {
          __typename
          created_at
          isLatest
          paymentImageURL
          promotion_snapshot
          repurchase_amount
          uid
          updated_at
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateRepurchaseMutation>response.data.createRepurchase;
  }
  async CreateSegments(
    input: CreateSegmentsInput
  ): Promise<CreateSegmentsMutation> {
    const statement = `mutation CreateSegments($input: CreateSegmentsInput!) {
        createSegments(input: $input) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          offerRepeatLimit
          offerShowTiming
          offerType
          onlyNewCards
          paymentImageURL
          promotionRef
          redirectToPromotion
          status
          uid
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateSegmentsMutation>response.data.createSegments;
  }
  async CreateTrackingUsers(
    input: CreateTrackingUsersInput
  ): Promise<CreateTrackingUsersMutation> {
    const statement = `mutation CreateTrackingUsers($input: CreateTrackingUsersInput!) {
        createTrackingUsers(input: $input) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateTrackingUsersMutation>response.data.createTrackingUsers;
  }
  async CreateWebClientSettings(
    input: CreateWebClientSettingsInput
  ): Promise<CreateWebClientSettingsMutation> {
    const statement = `mutation CreateWebClientSettings($input: CreateWebClientSettingsInput!) {
        createWebClientSettings(input: $input) {
          __typename
          environment
          maxAccountTimeLimit
          minAccountTimeLimit
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateWebClientSettingsMutation>(
      response.data.createWebClientSettings
    );
  }
  async DeleteCardSettings(
    input: DeleteCardSettingsInput
  ): Promise<DeleteCardSettingsMutation> {
    const statement = `mutation DeleteCardSettings($input: DeleteCardSettingsInput!) {
        deleteCardSettings(input: $input) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteCardSettingsMutation>response.data.deleteCardSettings;
  }
  async DeleteOneClickUserLog(
    input: DeleteOneClickUserLogInput
  ): Promise<DeleteOneClickUserLogMutation> {
    const statement = `mutation DeleteOneClickUserLog($input: DeleteOneClickUserLogInput!) {
        deleteOneClickUserLog(input: $input) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteOneClickUserLogMutation>response.data.deleteOneClickUserLog;
  }
  async DeletePromotions(
    input: DeletePromotionsInput
  ): Promise<DeletePromotionsMutation> {
    const statement = `mutation DeletePromotions($input: DeletePromotionsInput!) {
        deletePromotions(input: $input) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          isSegmentedOffer
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfAdminPromotion
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeletePromotionsMutation>response.data.deletePromotions;
  }
  async DeleteRepurchase(
    input: DeleteRepurchaseInput
  ): Promise<DeleteRepurchaseMutation> {
    const statement = `mutation DeleteRepurchase($input: DeleteRepurchaseInput!) {
        deleteRepurchase(input: $input) {
          __typename
          created_at
          isLatest
          paymentImageURL
          promotion_snapshot
          repurchase_amount
          uid
          updated_at
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteRepurchaseMutation>response.data.deleteRepurchase;
  }
  async DeleteSegments(
    input: DeleteSegmentsInput
  ): Promise<DeleteSegmentsMutation> {
    const statement = `mutation DeleteSegments($input: DeleteSegmentsInput!) {
        deleteSegments(input: $input) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          offerRepeatLimit
          offerShowTiming
          offerType
          onlyNewCards
          paymentImageURL
          promotionRef
          redirectToPromotion
          status
          uid
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteSegmentsMutation>response.data.deleteSegments;
  }
  async DeleteTrackingUsers(
    input: DeleteTrackingUsersInput
  ): Promise<DeleteTrackingUsersMutation> {
    const statement = `mutation DeleteTrackingUsers($input: DeleteTrackingUsersInput!) {
        deleteTrackingUsers(input: $input) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteTrackingUsersMutation>response.data.deleteTrackingUsers;
  }
  async DeleteWebClientSettings(
    input: DeleteWebClientSettingsInput
  ): Promise<DeleteWebClientSettingsMutation> {
    const statement = `mutation DeleteWebClientSettings($input: DeleteWebClientSettingsInput!) {
        deleteWebClientSettings(input: $input) {
          __typename
          environment
          maxAccountTimeLimit
          minAccountTimeLimit
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteWebClientSettingsMutation>(
      response.data.deleteWebClientSettings
    );
  }
  async UpdateCardSettings(
    input: UpdateCardSettingsInput
  ): Promise<UpdateCardSettingsMutation> {
    const statement = `mutation UpdateCardSettings($input: UpdateCardSettingsInput!) {
        updateCardSettings(input: $input) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateCardSettingsMutation>response.data.updateCardSettings;
  }
  async UpdateOneClickUserLog(
    input: UpdateOneClickUserLogInput
  ): Promise<UpdateOneClickUserLogMutation> {
    const statement = `mutation UpdateOneClickUserLog($input: UpdateOneClickUserLogInput!) {
        updateOneClickUserLog(input: $input) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateOneClickUserLogMutation>response.data.updateOneClickUserLog;
  }
  async UpdatePromotions(
    input: UpdatePromotionsInput
  ): Promise<UpdatePromotionsMutation> {
    const statement = `mutation UpdatePromotions($input: UpdatePromotionsInput!) {
        updatePromotions(input: $input) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          isSegmentedOffer
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfAdminPromotion
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdatePromotionsMutation>response.data.updatePromotions;
  }
  async UpdateRepurchase(
    input: UpdateRepurchaseInput
  ): Promise<UpdateRepurchaseMutation> {
    const statement = `mutation UpdateRepurchase($input: UpdateRepurchaseInput!) {
        updateRepurchase(input: $input) {
          __typename
          created_at
          isLatest
          paymentImageURL
          promotion_snapshot
          repurchase_amount
          uid
          updated_at
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateRepurchaseMutation>response.data.updateRepurchase;
  }
  async UpdateSegments(
    input: UpdateSegmentsInput
  ): Promise<UpdateSegmentsMutation> {
    const statement = `mutation UpdateSegments($input: UpdateSegmentsInput!) {
        updateSegments(input: $input) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          offerRepeatLimit
          offerShowTiming
          offerType
          onlyNewCards
          paymentImageURL
          promotionRef
          redirectToPromotion
          status
          uid
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateSegmentsMutation>response.data.updateSegments;
  }
  async UpdateTrackingUsers(
    input: UpdateTrackingUsersInput
  ): Promise<UpdateTrackingUsersMutation> {
    const statement = `mutation UpdateTrackingUsers($input: UpdateTrackingUsersInput!) {
        updateTrackingUsers(input: $input) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateTrackingUsersMutation>response.data.updateTrackingUsers;
  }
  async UpdateWebClientSettings(
    input: UpdateWebClientSettingsInput
  ): Promise<UpdateWebClientSettingsMutation> {
    const statement = `mutation UpdateWebClientSettings($input: UpdateWebClientSettingsInput!) {
        updateWebClientSettings(input: $input) {
          __typename
          environment
          maxAccountTimeLimit
          minAccountTimeLimit
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateWebClientSettingsMutation>(
      response.data.updateWebClientSettings
    );
  }
  async GetCardSettings(account: number): Promise<GetCardSettingsQuery> {
    const statement = `query GetCardSettings($account: Float!) {
        getCardSettings(account: $account) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {
      account
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetCardSettingsQuery>response.data.getCardSettings;
  }
  async GetOneClickUserLog(uid: string): Promise<GetOneClickUserLogQuery> {
    const statement = `query GetOneClickUserLog($uid: String!) {
        getOneClickUserLog(uid: $uid) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {
      uid
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetOneClickUserLogQuery>response.data.getOneClickUserLog;
  }
  async GetPromotions(uid: string): Promise<GetPromotionsQuery> {
    const statement = `query GetPromotions($uid: ID!) {
        getPromotions(uid: $uid) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          isSegmentedOffer
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfAdminPromotion
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      uid
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetPromotionsQuery>response.data.getPromotions;
  }
  async GetRepurchase(uid: string): Promise<GetRepurchaseQuery> {
    const statement = `query GetRepurchase($uid: String!) {
        getRepurchase(uid: $uid) {
          __typename
          created_at
          isLatest
          paymentImageURL
          promotion_snapshot
          repurchase_amount
          uid
          updated_at
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {
      uid
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetRepurchaseQuery>response.data.getRepurchase;
  }
  async GetSegments(uid: string): Promise<GetSegmentsQuery> {
    const statement = `query GetSegments($uid: String!) {
        getSegments(uid: $uid) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          offerRepeatLimit
          offerShowTiming
          offerType
          onlyNewCards
          paymentImageURL
          promotionRef
          redirectToPromotion
          status
          uid
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {
      uid
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetSegmentsQuery>response.data.getSegments;
  }
  async GetTrackingUsers(uid: string): Promise<GetTrackingUsersQuery> {
    const statement = `query GetTrackingUsers($uid: String!) {
        getTrackingUsers(uid: $uid) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      uid
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetTrackingUsersQuery>response.data.getTrackingUsers;
  }
  async GetWebClientSettings(uid: string): Promise<GetWebClientSettingsQuery> {
    const statement = `query GetWebClientSettings($uid: String!) {
        getWebClientSettings(uid: $uid) {
          __typename
          environment
          maxAccountTimeLimit
          minAccountTimeLimit
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      uid
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetWebClientSettingsQuery>response.data.getWebClientSettings;
  }
  async ListCardSettings(
    filter?: TableCardSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCardSettingsQuery> {
    const statement = `query ListCardSettings($filter: TableCardSettingsFilterInput, $limit: Int, $nextToken: String) {
        listCardSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            account
            segmentRef
            showSpecialOffer
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListCardSettingsQuery>response.data.listCardSettings;
  }
  async ListOneClickUserLogs(
    filter?: TableOneClickUserLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOneClickUserLogsQuery> {
    const statement = `query ListOneClickUserLogs($filter: TableOneClickUserLogFilterInput, $limit: Int, $nextToken: String) {
        listOneClickUserLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            authorizationCode
            cardNumber
            cardType
            email
            responseCode
            uid
            username
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListOneClickUserLogsQuery>response.data.listOneClickUserLogs;
  }
  async ListPromotions(
    filter?: TablePromotionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPromotionsQuery> {
    const statement = `query ListPromotions($filter: TablePromotionsFilterInput, $limit: Int, $nextToken: String) {
        listPromotions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            amount
            basesDescription
            bonusAmount
            bonusToken
            color
            daysAvailable
            durationDescription
            enabled
            finalDate
            initialDate
            isDeleted
            isEnabledAllCustomers
            isSegmentedOffer
            name
            paymentAmount
            segment_uid
            tickets
            token
            typeOfAdminPromotion
            typeOfPromotion
            uid
            updateTime
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListPromotionsQuery>response.data.listPromotions;
  }
  async ListRepurchases(
    filter?: TableRepurchaseFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRepurchasesQuery> {
    const statement = `query ListRepurchases($filter: TableRepurchaseFilterInput, $limit: Int, $nextToken: String) {
        listRepurchases(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            created_at
            isLatest
            paymentImageURL
            promotion_snapshot
            repurchase_amount
            uid
            updated_at
            waiverImageURL
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListRepurchasesQuery>response.data.listRepurchases;
  }
  async ListSegments(
    filter?: TableSegmentsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSegmentsQuery> {
    const statement = `query ListSegments($filter: TableSegmentsFilterInput, $limit: Int, $nextToken: String) {
        listSegments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            creationDate
            isDeleted
            lastUpdate
            name
            offerRepeatLimit
            offerShowTiming
            offerType
            onlyNewCards
            paymentImageURL
            promotionRef
            redirectToPromotion
            status
            uid
            waiverImageURL
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListSegmentsQuery>response.data.listSegments;
  }
  async ListTrackingUsers(
    filter?: TableTrackingUsersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTrackingUsersQuery> {
    const statement = `query ListTrackingUsers($filter: TableTrackingUsersFilterInput, $limit: Int, $nextToken: String) {
        listTrackingUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            cardNumber
            fidelity
            finalDate
            initialDate
            lastStepReached
            paymentAmount
            paymentMethod
            promotionCash
            promotionCashBonus
            promotionTickets
            promotionTokenBonus
            promotionTokens
            registeredEmail
            uid
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListTrackingUsersQuery>response.data.listTrackingUsers;
  }
  async ListWebClientSettings(
    filter?: TableWebClientSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListWebClientSettingsQuery> {
    const statement = `query ListWebClientSettings($filter: TableWebClientSettingsFilterInput, $limit: Int, $nextToken: String) {
        listWebClientSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            environment
            maxAccountTimeLimit
            minAccountTimeLimit
            uid
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListWebClientSettingsQuery>response.data.listWebClientSettings;
  }
  OnCreateCardSettingsListener(
    account?: number,
    showSpecialOffer?: boolean
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateCardSettings">>
  > {
    const statement = `subscription OnCreateCardSettings($account: Float, $showSpecialOffer: Boolean) {
        onCreateCardSettings(account: $account, showSpecialOffer: $showSpecialOffer) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (account) {
      gqlAPIServiceArguments.account = account;
    }
    if (showSpecialOffer) {
      gqlAPIServiceArguments.showSpecialOffer = showSpecialOffer;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateOneClickUserLogListener(
    authorizationCode?: number,
    email?: string,
    responseCode?: number,
    uid?: string,
    username?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateOneClickUserLog">>
  > {
    const statement = `subscription OnCreateOneClickUserLog($authorizationCode: Int, $email: String, $responseCode: Int, $uid: String, $username: String) {
        onCreateOneClickUserLog(
          authorizationCode: $authorizationCode
          email: $email
          responseCode: $responseCode
          uid: $uid
          username: $username
        ) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (authorizationCode) {
      gqlAPIServiceArguments.authorizationCode = authorizationCode;
    }
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    if (responseCode) {
      gqlAPIServiceArguments.responseCode = responseCode;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreatePromotionsListener(
    amount?: number,
    basesDescription?: string,
    bonusAmount?: number,
    bonusToken?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreatePromotions">>
  > {
    const statement = `subscription OnCreatePromotions($amount: Int, $basesDescription: String, $bonusAmount: Int, $bonusToken: Int, $uid: ID) {
        onCreatePromotions(
          amount: $amount
          basesDescription: $basesDescription
          bonusAmount: $bonusAmount
          bonusToken: $bonusToken
          uid: $uid
        ) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          isSegmentedOffer
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfAdminPromotion
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (amount) {
      gqlAPIServiceArguments.amount = amount;
    }
    if (basesDescription) {
      gqlAPIServiceArguments.basesDescription = basesDescription;
    }
    if (bonusAmount) {
      gqlAPIServiceArguments.bonusAmount = bonusAmount;
    }
    if (bonusToken) {
      gqlAPIServiceArguments.bonusToken = bonusToken;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateRepurchaseListener(
    paymentImageURL?: string,
    promotion_snapshot?: string,
    repurchase_amount?: number,
    uid?: string,
    waiverImageURL?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateRepurchase">>
  > {
    const statement = `subscription OnCreateRepurchase($paymentImageURL: String, $promotion_snapshot: AWSJSON, $repurchase_amount: Int, $uid: String, $waiverImageURL: String) {
        onCreateRepurchase(
          paymentImageURL: $paymentImageURL
          promotion_snapshot: $promotion_snapshot
          repurchase_amount: $repurchase_amount
          uid: $uid
          waiverImageURL: $waiverImageURL
        ) {
          __typename
          created_at
          isLatest
          paymentImageURL
          promotion_snapshot
          repurchase_amount
          uid
          updated_at
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (paymentImageURL) {
      gqlAPIServiceArguments.paymentImageURL = paymentImageURL;
    }
    if (promotion_snapshot) {
      gqlAPIServiceArguments.promotion_snapshot = promotion_snapshot;
    }
    if (repurchase_amount) {
      gqlAPIServiceArguments.repurchase_amount = repurchase_amount;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    if (waiverImageURL) {
      gqlAPIServiceArguments.waiverImageURL = waiverImageURL;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateSegmentsListener(
    creationDate?: string,
    lastUpdate?: string,
    name?: string,
    status?: boolean,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateSegments">>
  > {
    const statement = `subscription OnCreateSegments($creationDate: AWSDateTime, $lastUpdate: AWSDateTime, $name: String, $status: Boolean, $uid: String) {
        onCreateSegments(
          creationDate: $creationDate
          lastUpdate: $lastUpdate
          name: $name
          status: $status
          uid: $uid
        ) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          offerRepeatLimit
          offerShowTiming
          offerType
          onlyNewCards
          paymentImageURL
          promotionRef
          redirectToPromotion
          status
          uid
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (creationDate) {
      gqlAPIServiceArguments.creationDate = creationDate;
    }
    if (lastUpdate) {
      gqlAPIServiceArguments.lastUpdate = lastUpdate;
    }
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateTrackingUsersListener(
    cardNumber?: number,
    fidelity?: string,
    finalDate?: number,
    initialDate?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateTrackingUsers">>
  > {
    const statement = `subscription OnCreateTrackingUsers($cardNumber: Float, $fidelity: String, $finalDate: Float, $initialDate: Float, $uid: String) {
        onCreateTrackingUsers(
          cardNumber: $cardNumber
          fidelity: $fidelity
          finalDate: $finalDate
          initialDate: $initialDate
          uid: $uid
        ) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cardNumber) {
      gqlAPIServiceArguments.cardNumber = cardNumber;
    }
    if (fidelity) {
      gqlAPIServiceArguments.fidelity = fidelity;
    }
    if (finalDate) {
      gqlAPIServiceArguments.finalDate = finalDate;
    }
    if (initialDate) {
      gqlAPIServiceArguments.initialDate = initialDate;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateWebClientSettingsListener(
    environment?: string,
    maxAccountTimeLimit?: number,
    minAccountTimeLimit?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateWebClientSettings">>
  > {
    const statement = `subscription OnCreateWebClientSettings($environment: String, $maxAccountTimeLimit: Int, $minAccountTimeLimit: Int, $uid: String) {
        onCreateWebClientSettings(
          environment: $environment
          maxAccountTimeLimit: $maxAccountTimeLimit
          minAccountTimeLimit: $minAccountTimeLimit
          uid: $uid
        ) {
          __typename
          environment
          maxAccountTimeLimit
          minAccountTimeLimit
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (environment) {
      gqlAPIServiceArguments.environment = environment;
    }
    if (maxAccountTimeLimit) {
      gqlAPIServiceArguments.maxAccountTimeLimit = maxAccountTimeLimit;
    }
    if (minAccountTimeLimit) {
      gqlAPIServiceArguments.minAccountTimeLimit = minAccountTimeLimit;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteCardSettingsListener(
    account?: number,
    showSpecialOffer?: boolean
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteCardSettings">>
  > {
    const statement = `subscription OnDeleteCardSettings($account: Float, $showSpecialOffer: Boolean) {
        onDeleteCardSettings(account: $account, showSpecialOffer: $showSpecialOffer) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (account) {
      gqlAPIServiceArguments.account = account;
    }
    if (showSpecialOffer) {
      gqlAPIServiceArguments.showSpecialOffer = showSpecialOffer;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteOneClickUserLogListener(
    authorizationCode?: number,
    email?: string,
    responseCode?: number,
    uid?: string,
    username?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteOneClickUserLog">>
  > {
    const statement = `subscription OnDeleteOneClickUserLog($authorizationCode: Int, $email: String, $responseCode: Int, $uid: String, $username: String) {
        onDeleteOneClickUserLog(
          authorizationCode: $authorizationCode
          email: $email
          responseCode: $responseCode
          uid: $uid
          username: $username
        ) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (authorizationCode) {
      gqlAPIServiceArguments.authorizationCode = authorizationCode;
    }
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    if (responseCode) {
      gqlAPIServiceArguments.responseCode = responseCode;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeletePromotionsListener(
    amount?: number,
    basesDescription?: string,
    bonusAmount?: number,
    bonusToken?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeletePromotions">>
  > {
    const statement = `subscription OnDeletePromotions($amount: Int, $basesDescription: String, $bonusAmount: Int, $bonusToken: Int, $uid: ID) {
        onDeletePromotions(
          amount: $amount
          basesDescription: $basesDescription
          bonusAmount: $bonusAmount
          bonusToken: $bonusToken
          uid: $uid
        ) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          isSegmentedOffer
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfAdminPromotion
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (amount) {
      gqlAPIServiceArguments.amount = amount;
    }
    if (basesDescription) {
      gqlAPIServiceArguments.basesDescription = basesDescription;
    }
    if (bonusAmount) {
      gqlAPIServiceArguments.bonusAmount = bonusAmount;
    }
    if (bonusToken) {
      gqlAPIServiceArguments.bonusToken = bonusToken;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteRepurchaseListener(
    paymentImageURL?: string,
    promotion_snapshot?: string,
    repurchase_amount?: number,
    uid?: string,
    waiverImageURL?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteRepurchase">>
  > {
    const statement = `subscription OnDeleteRepurchase($paymentImageURL: String, $promotion_snapshot: AWSJSON, $repurchase_amount: Int, $uid: String, $waiverImageURL: String) {
        onDeleteRepurchase(
          paymentImageURL: $paymentImageURL
          promotion_snapshot: $promotion_snapshot
          repurchase_amount: $repurchase_amount
          uid: $uid
          waiverImageURL: $waiverImageURL
        ) {
          __typename
          created_at
          isLatest
          paymentImageURL
          promotion_snapshot
          repurchase_amount
          uid
          updated_at
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (paymentImageURL) {
      gqlAPIServiceArguments.paymentImageURL = paymentImageURL;
    }
    if (promotion_snapshot) {
      gqlAPIServiceArguments.promotion_snapshot = promotion_snapshot;
    }
    if (repurchase_amount) {
      gqlAPIServiceArguments.repurchase_amount = repurchase_amount;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    if (waiverImageURL) {
      gqlAPIServiceArguments.waiverImageURL = waiverImageURL;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteSegmentsListener(
    creationDate?: string,
    lastUpdate?: string,
    name?: string,
    status?: boolean,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteSegments">>
  > {
    const statement = `subscription OnDeleteSegments($creationDate: AWSDateTime, $lastUpdate: AWSDateTime, $name: String, $status: Boolean, $uid: String) {
        onDeleteSegments(
          creationDate: $creationDate
          lastUpdate: $lastUpdate
          name: $name
          status: $status
          uid: $uid
        ) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          offerRepeatLimit
          offerShowTiming
          offerType
          onlyNewCards
          paymentImageURL
          promotionRef
          redirectToPromotion
          status
          uid
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (creationDate) {
      gqlAPIServiceArguments.creationDate = creationDate;
    }
    if (lastUpdate) {
      gqlAPIServiceArguments.lastUpdate = lastUpdate;
    }
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteTrackingUsersListener(
    cardNumber?: number,
    fidelity?: string,
    finalDate?: number,
    initialDate?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteTrackingUsers">>
  > {
    const statement = `subscription OnDeleteTrackingUsers($cardNumber: Float, $fidelity: String, $finalDate: Float, $initialDate: Float, $uid: String) {
        onDeleteTrackingUsers(
          cardNumber: $cardNumber
          fidelity: $fidelity
          finalDate: $finalDate
          initialDate: $initialDate
          uid: $uid
        ) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cardNumber) {
      gqlAPIServiceArguments.cardNumber = cardNumber;
    }
    if (fidelity) {
      gqlAPIServiceArguments.fidelity = fidelity;
    }
    if (finalDate) {
      gqlAPIServiceArguments.finalDate = finalDate;
    }
    if (initialDate) {
      gqlAPIServiceArguments.initialDate = initialDate;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteWebClientSettingsListener(
    environment?: string,
    maxAccountTimeLimit?: number,
    minAccountTimeLimit?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteWebClientSettings">>
  > {
    const statement = `subscription OnDeleteWebClientSettings($environment: String, $maxAccountTimeLimit: Int, $minAccountTimeLimit: Int, $uid: String) {
        onDeleteWebClientSettings(
          environment: $environment
          maxAccountTimeLimit: $maxAccountTimeLimit
          minAccountTimeLimit: $minAccountTimeLimit
          uid: $uid
        ) {
          __typename
          environment
          maxAccountTimeLimit
          minAccountTimeLimit
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (environment) {
      gqlAPIServiceArguments.environment = environment;
    }
    if (maxAccountTimeLimit) {
      gqlAPIServiceArguments.maxAccountTimeLimit = maxAccountTimeLimit;
    }
    if (minAccountTimeLimit) {
      gqlAPIServiceArguments.minAccountTimeLimit = minAccountTimeLimit;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateCardSettingsListener(
    account?: number,
    showSpecialOffer?: boolean
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateCardSettings">>
  > {
    const statement = `subscription OnUpdateCardSettings($account: Float, $showSpecialOffer: Boolean) {
        onUpdateCardSettings(account: $account, showSpecialOffer: $showSpecialOffer) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (account) {
      gqlAPIServiceArguments.account = account;
    }
    if (showSpecialOffer) {
      gqlAPIServiceArguments.showSpecialOffer = showSpecialOffer;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateOneClickUserLogListener(
    authorizationCode?: number,
    email?: string,
    responseCode?: number,
    uid?: string,
    username?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateOneClickUserLog">>
  > {
    const statement = `subscription OnUpdateOneClickUserLog($authorizationCode: Int, $email: String, $responseCode: Int, $uid: String, $username: String) {
        onUpdateOneClickUserLog(
          authorizationCode: $authorizationCode
          email: $email
          responseCode: $responseCode
          uid: $uid
          username: $username
        ) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (authorizationCode) {
      gqlAPIServiceArguments.authorizationCode = authorizationCode;
    }
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    if (responseCode) {
      gqlAPIServiceArguments.responseCode = responseCode;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdatePromotionsListener(
    amount?: number,
    basesDescription?: string,
    bonusAmount?: number,
    bonusToken?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdatePromotions">>
  > {
    const statement = `subscription OnUpdatePromotions($amount: Int, $basesDescription: String, $bonusAmount: Int, $bonusToken: Int, $uid: ID) {
        onUpdatePromotions(
          amount: $amount
          basesDescription: $basesDescription
          bonusAmount: $bonusAmount
          bonusToken: $bonusToken
          uid: $uid
        ) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          isSegmentedOffer
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfAdminPromotion
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (amount) {
      gqlAPIServiceArguments.amount = amount;
    }
    if (basesDescription) {
      gqlAPIServiceArguments.basesDescription = basesDescription;
    }
    if (bonusAmount) {
      gqlAPIServiceArguments.bonusAmount = bonusAmount;
    }
    if (bonusToken) {
      gqlAPIServiceArguments.bonusToken = bonusToken;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateRepurchaseListener(
    paymentImageURL?: string,
    promotion_snapshot?: string,
    repurchase_amount?: number,
    uid?: string,
    waiverImageURL?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateRepurchase">>
  > {
    const statement = `subscription OnUpdateRepurchase($paymentImageURL: String, $promotion_snapshot: AWSJSON, $repurchase_amount: Int, $uid: String, $waiverImageURL: String) {
        onUpdateRepurchase(
          paymentImageURL: $paymentImageURL
          promotion_snapshot: $promotion_snapshot
          repurchase_amount: $repurchase_amount
          uid: $uid
          waiverImageURL: $waiverImageURL
        ) {
          __typename
          created_at
          isLatest
          paymentImageURL
          promotion_snapshot
          repurchase_amount
          uid
          updated_at
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (paymentImageURL) {
      gqlAPIServiceArguments.paymentImageURL = paymentImageURL;
    }
    if (promotion_snapshot) {
      gqlAPIServiceArguments.promotion_snapshot = promotion_snapshot;
    }
    if (repurchase_amount) {
      gqlAPIServiceArguments.repurchase_amount = repurchase_amount;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    if (waiverImageURL) {
      gqlAPIServiceArguments.waiverImageURL = waiverImageURL;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateSegmentsListener(
    creationDate?: string,
    lastUpdate?: string,
    name?: string,
    status?: boolean,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateSegments">>
  > {
    const statement = `subscription OnUpdateSegments($creationDate: AWSDateTime, $lastUpdate: AWSDateTime, $name: String, $status: Boolean, $uid: String) {
        onUpdateSegments(
          creationDate: $creationDate
          lastUpdate: $lastUpdate
          name: $name
          status: $status
          uid: $uid
        ) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          offerRepeatLimit
          offerShowTiming
          offerType
          onlyNewCards
          paymentImageURL
          promotionRef
          redirectToPromotion
          status
          uid
          waiverImageURL
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (creationDate) {
      gqlAPIServiceArguments.creationDate = creationDate;
    }
    if (lastUpdate) {
      gqlAPIServiceArguments.lastUpdate = lastUpdate;
    }
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateTrackingUsersListener(
    cardNumber?: number,
    fidelity?: string,
    finalDate?: number,
    initialDate?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateTrackingUsers">>
  > {
    const statement = `subscription OnUpdateTrackingUsers($cardNumber: Float, $fidelity: String, $finalDate: Float, $initialDate: Float, $uid: String) {
        onUpdateTrackingUsers(
          cardNumber: $cardNumber
          fidelity: $fidelity
          finalDate: $finalDate
          initialDate: $initialDate
          uid: $uid
        ) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cardNumber) {
      gqlAPIServiceArguments.cardNumber = cardNumber;
    }
    if (fidelity) {
      gqlAPIServiceArguments.fidelity = fidelity;
    }
    if (finalDate) {
      gqlAPIServiceArguments.finalDate = finalDate;
    }
    if (initialDate) {
      gqlAPIServiceArguments.initialDate = initialDate;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateWebClientSettingsListener(
    environment?: string,
    maxAccountTimeLimit?: number,
    minAccountTimeLimit?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateWebClientSettings">>
  > {
    const statement = `subscription OnUpdateWebClientSettings($environment: String, $maxAccountTimeLimit: Int, $minAccountTimeLimit: Int, $uid: String) {
        onUpdateWebClientSettings(
          environment: $environment
          maxAccountTimeLimit: $maxAccountTimeLimit
          minAccountTimeLimit: $minAccountTimeLimit
          uid: $uid
        ) {
          __typename
          environment
          maxAccountTimeLimit
          minAccountTimeLimit
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (environment) {
      gqlAPIServiceArguments.environment = environment;
    }
    if (maxAccountTimeLimit) {
      gqlAPIServiceArguments.maxAccountTimeLimit = maxAccountTimeLimit;
    }
    if (minAccountTimeLimit) {
      gqlAPIServiceArguments.minAccountTimeLimit = minAccountTimeLimit;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }
}
