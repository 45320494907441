import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-special-offer-term-dialog',
  templateUrl: './special-offer-term-dialog.component.html',
  styleUrls: ['./special-offer-term-dialog.component.scss'],
})
export class SpecialOfferTermDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message; string }, public dialogRef: MatDialogRef<SpecialOfferTermDialogComponent>) {}
}
