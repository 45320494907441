<mat-dialog-content class="dialog-content">
  <div class="dialog-header">
    <span class="title">Términos legales</span>
    <button mat-icon-button class="close-button" (click)="dialogRef?.close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="content-wrapper">
    <p class="text-justify">{{ data.message }}</p>
  </div>
</mat-dialog-content>
