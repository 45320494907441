import { DateTime } from 'luxon';
import { Promotions as AppSyncPromotion } from '../services/api.service';

type PromotionWithoutTypename = Omit<AppSyncPromotion, '__typename'>;
/**
 * Documentación
 *
 * uid: Identificador unico de 20 caracteres alphanumericos
 * name: Nombre de la promoción (Considerar que este aparece en el detalle de la boleta generada)
 * updateTime: Fecha ultima actualización de la promocion
 * basesDescription: Bases de la promoción.
 * durationDescription: Información sobre la duración de la promoción
 * initialDate: Fecha de Inicio de la promoción
 * finalDate: Fecha de termino de la promoción
 * paymentAmount: Monto a cobrar con el medio de pago,
 * amount: Monto a cargar,
 * bonusAmount: Monto adicional a cargar,
 * token: Numero de Jugadas,`
 * bonusToken: Bono adicional por Jugada,
 * tickets: Tickets,
 * enabled: Indicador de si la promocion esta activa
 * daysAvailable: Listado con los dias en que la promocion puede ser visualizada
 * isDeleted: Indicador de si la promoción fue eliminada
 * isEnabledAllCustomers: Indica si la promocion esta disponible para publico fidelizado o no
 * typeOfPromotion: Indica si la promocion es de tipo SOLO CASH, CASH & TICKETS o CASH & JUGADAS,
 */

export class PromotionModel {
  uid: string | null;
  amount: number;
  basesDescription: string | null;
  bonusAmount: number;
  bonusToken: number;
  color: string | null;
  daysAvailable: string[];
  durationDescription: string | null;
  enabled: boolean;
  finalDate: string | null;
  initialDate: string | null;
  isDeleted: boolean;
  name: string;
  paymentAmount: number;
  tickets: number;
  token: number;
  updateTime: Date;
  isEnabledAllCustomers: boolean;
  typeOfPromotion: string;
  segment_uid: string;
  isSegmentedOffer: boolean;
  typeOfAdminPromotion: 'NORMAL' | 'SEGMENTED' | 'REPURCHASE';
  private __typename: any;

  constructor(promotion?: PromotionWithoutTypename | null) {
    this.uid = promotion?.uid ?? null;
    this.amount = promotion?.amount ?? 0;
    this.basesDescription = promotion?.basesDescription ?? null;
    this.bonusAmount = promotion?.bonusAmount ?? 0;
    this.bonusToken = promotion?.bonusToken ?? 0;
    this.color = promotion?.color ?? null;
    this.daysAvailable = promotion?.daysAvailable ?? [];
    this.durationDescription = promotion?.durationDescription ?? null;
    this.enabled = promotion?.enabled ?? false;
    this.finalDate = promotion?.finalDate ?? null;
    this.initialDate = promotion?.initialDate ?? null;
    this.isDeleted = promotion?.isDeleted ?? false;
    this.name = promotion?.name ?? '';
    this.paymentAmount = promotion?.paymentAmount ?? 0;
    this.tickets = promotion?.tickets ?? 0;
    this.token = promotion?.token ?? 0;
    this.updateTime = promotion?.updateTime ? new Date(promotion.updateTime) : new Date();
    this.isEnabledAllCustomers = promotion?.isEnabledAllCustomers ?? false;
    this.typeOfPromotion = promotion?.typeOfPromotion || 'NORMAL';
    this.segment_uid = promotion?.segment_uid ?? '';
    this.isSegmentedOffer = promotion?.isSegmentedOffer ?? false;

    this.typeOfAdminPromotion = (['NORMAL', 'SEGMENTED', 'REPURCHASE'] as const).includes(promotion?.typeOfAdminPromotion as any)
      ? (promotion?.typeOfAdminPromotion as any)
      : 'NORMAL';
  }
}
