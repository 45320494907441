import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';

/**
 * Api is a generic REST Api handler. Set your API endpointCredentials first.
 */
@Injectable()
export class ApiRestService {
  constructor(public http: HttpClient) {}

  get(endpoint: string, reqOpts?: any) {
    return this.http.get(endpoint, reqOpts).pipe(timeout(30000));
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(endpoint, body, reqOpts).pipe(timeout(30000));
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(endpoint, body, reqOpts).pipe(timeout(30000));
  }

  delete(endpoint: string, reqOpts?: any) {
    return this.http.delete(endpoint, reqOpts).pipe(timeout(30000));
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(endpoint, body, reqOpts).pipe(timeout(30000));
  }

  query(endpoint: string, type: string, params?: any, reqOpts?: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (type === 'GET') {
          const resp = await this.getQuery(endpoint);
          resolve(resp);
        }

        if (type === 'POST') {
          const resp = await this.postQuery(endpoint, params);
          resolve(resp);
        }

        if (type === 'PUT') {
          const resp = await this.putQuery(endpoint, params);
          resolve(resp);
        }
      } catch (err) {
        // console.error(err);
        reject(err);
      }
    });
  }

  getQuery(endpoint: string, headers?: HttpHeaders): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get(endpoint, { headers: headers, observe: 'response' })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  postQuery(endpoint: string, headers?: HttpHeaders, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.post(endpoint, params, { headers: headers, observe: 'response' })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  putQuery(endpoint: string, headers?: HttpHeaders, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.put(endpoint, params, { headers: headers, observe: 'response' })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
