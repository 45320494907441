import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-term-and-conditions-dialog',
  templateUrl: './term-and-conditions-dialog.component.html',
  styleUrls: ['./term-and-conditions-dialog.component.scss']
})
export class TermAndConditionsDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
