// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  amplifySettings: {
    aws_appsync_graphqlEndpoint: 'https://4l5hdpbbencixed5trquw52ooy.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-p65byp5rb5cylcid2cne3sn5my',
  },

  endpoints: {
    returnTo: 'https://dev.client.happyland.innervycs.com',
    transbank: 'https://qa.transbank.api.happyland.innervycs.com',
    status: 'https://qa.status.api.happyland.innervycs.com',
    secure: 'https://qa.secure.api.happyland.innervycs.com',
    register: 'https://qa.register.api.happyland.innervycs.com',
    fPay: 'https://qa.fpay.api.happyland.innervycs.com',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
