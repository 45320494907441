import { Injectable } from '@angular/core';
import { APIService, ListPromotionsQuery } from '../api.service';
import { PromotionModel } from 'src/app/model/promotion.model';

@Injectable({
  providedIn: 'root',
})
export class PromotionsService {
  constructor(private apiService: APIService) {}

  async searchPromotionsByTokens(nextToken: string, previousResult: PromotionModel[], customRequest: any): Promise<PromotionModel[]> {
    const listOfPromotionsResponse: ListPromotionsQuery = await this.apiService.ListPromotions(customRequest, null, nextToken);

    const listPromotions: PromotionModel[] = previousResult;
    listOfPromotionsResponse.items.forEach((element) => {
      listPromotions.push(new PromotionModel(element));
    });

    if (listOfPromotionsResponse.nextToken !== null) {
      const newPreviousResult = await this.searchPromotionsByTokens(listOfPromotionsResponse.nextToken, previousResult, customRequest);
      return Promise.resolve(newPreviousResult);
    } else {
      return Promise.resolve(previousResult);
    }
  }

  async searchPromotionByUID(promotionUID: string, nextToken: string, previousResult: PromotionModel[]): Promise<PromotionModel[] | null> {
    const listOfPromotionsResponse: ListPromotionsQuery = await this.apiService.ListPromotions({ uid: { eq: promotionUID } }, null, nextToken);

    const listPromotions: PromotionModel[] = previousResult;
    listOfPromotionsResponse.items.forEach((element) => {
      listPromotions.push(new PromotionModel(element));
    });

    if (listOfPromotionsResponse.nextToken !== null) {
      const newPreviousResult = await this.searchPromotionByUID(promotionUID, listOfPromotionsResponse.nextToken, previousResult);
      return Promise.resolve(newPreviousResult);
    } else {
      return Promise.resolve(previousResult);
    }
  }
}
