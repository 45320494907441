<div mat-dialog-title>Términos y Condiciones de Uso Happyland</div>
<mat-dialog-content>
  <article>
    <p class="alert alert-success">
      <strong>AVISO:</strong><br>
      Lea estos Términos de Uso con atención antes de utilizar este sitio web.<br>
      <strong>ACLARACIÓN:</strong><br>
      Al utilizar este sitio, usted en calidad de usuario de Internet (en adelante «el usuario») acepta los siguientes términos de uso. Si no está de acuerdo con alguno de los siguientes Términos de Uso, no utilice este sitio.
    </p>

    <h3><strong>TÉRMINOS Y CONDICIONES DE USO WWW.HAPPYLAND.CL HAPPYLAND M.R.</strong></h3>
    <p class="text-justified">
      (en adelante “La Empresa”), se reserva el derecho, a su entera discreción, de modificar, alterar o actualizar de algún modo estos Términos de Uso en cualquier momento y usted acepta quedar obligado por dichas modificaciones,alteraciones o actualizaciones.
      Independientemente de los cambios que se apliquen a estos Términos de Uso, nunca se utilizará información que pueda identificarlo personalmente con fines sustancialmente diferentes a los establecidos en estos Términos de Uso, sin primero proporcionarle una oportunidad significativa de no participar o de evitar dicho uso.
    </p>

    <h3><strong>ADVERTENCIA ESPECIAL PARA LOS PADRES EN LO QUE RESPECTA A LA PRIVACIDAD:</strong></h3>
    <p class="text-justified">
      La Empresa realiza un gran esfuerzo por alentar a los niños a que consulten a sus padres antes de suministrar datos.
      Sin embargo, considera que los padres deben supervisar las actividades que realizan sus hijos en línea y analizar la posibilidad de utilizar herramientas de control para padres, ofrecidas por servicios en línea y fabricantes de software, que ayuden a proporcionar un entorno en Internet adecuado para los niños.
      Estas herramientas también pueden impedir que los niños divulguen en línea su nombre, dirección y otra información personal sin autorización de sus padres.
    </p>

    <h3><strong>MODIFICACIÓN DE CONTENIDOS:</strong></h3>
    <p class="text-justified">
      La Empresa se reserva el derecho a modificar unilateralmente y sin necesidad de aviso previo, los contenidos e información proporcionada en este sitio, así como su diseño, configuración, y condiciones generales y particulares de uso.
    </p>

    <h3><strong>DERECHOS RESERVADOS, REGISTROS DE LOS USUARIOS, CLAVES Y SU USO DILIGENTE:</strong></h3>
    <p class="text-justified">
      La Empresa podrá exigir, para el uso de ciertos servicios o contenidos del sitio, que el usuario se registre en un formulario especial, en el cual, además de facilitar sus datos personales esenciales, deberá seleccionar, usar y conservar un nombre de usuario y una clave secreta de acceso.
      El usuario elegirá libremente un nombre de usuario y una clave secreta, salvo las restricciones propias del sistema de base de datos, y en esta elección, no podrá seleccionar nombres o palabras injuriosas, malsonantes, o contrarias a la moral y a las buenas costumbres.
      En este sentido, el usuario no podrá seleccionar nombres o palabras coincidentes con marcas comerciales, nombres o seudónimos de personajes públicos y de relevancia notoria, siempre que no esté autorizado para ello, o con derechos, denominaciones, o expresiones que sean de propiedad exclusiva, o contrarios a la ley, a la moral, al Orden Público, o a las buenas costumbres.
      El usuario se obliga a hacer un uso diligente de su nombre de usuario y clave de acceso, para lo cuál no podrá cederlo bajo ninguna forma a una tercera persona, natural o jurídica, debiendo informar a la empresa cualquier circunstancia de extravío o uso anómalo.
      Todos los contenidos de este sitio, la información y servicios que se proporcionan a través del mismo, así como su diagramación y diseño, incluyendo las fotografías, son de propiedad exclusiva de la Empresa, por lo tanto ésta no concede ninguna licencia, autorización o permiso de uso de ninguna clase respecto de sus derechos de propiedad intelectual e industrial, o sobre cualquier otra propiedad o derecho vinculado al sitio.
      Este sitio es para el uso personal y no comercial del usuario, quien no puede modificar, copiar, distribuir, transmitir, exhibir, ejecutar, reproducir, publicar, licenciar, crear trabajos derivados de, transferir o vender cualquier información, software, productos o servicios obtenidos en este sitio.
      Se deja expresamente establecido que ciertos productos, servicios y empresas mencionadas u ofrecidos en el sitio pueden estar con derechos reservados de sus respectivos propietarios, por lo que corresponde al usuario hacer un correcto uso de ellos.
    </p>

    <h3><strong>OBLIGACIONES ESENCIALES DEL USUARIO:</strong></h3>
    <p class="text-justified">
      El usuario se obliga a hacer un uso lícito, racional, no doloso ni negligente, de los servicios, productos y contenidos del sitio en general; comprometiéndose por ello a no utilizarlos con fines o efectos contrarios a ley, a las buenas costumbres generalmente aceptadas, a la moral, al Orden Público, y a estas Condiciones Generales, o con fines dañinos o lesivos a derechos e intereses de terceras personas naturaleso jurídicas o de la empresa.
      El usuario se obliga a no intentar obtener y a no obtener a través del sitio, contenidos, mensajes, gráficos, fotografías, videos, reproducciones sonoras y archivos en general de cualquier clase o naturaleza por medios o vías distintas de las señaladas por la empresa y proporcionadas por ésta para su natural obtención. Por tanto, todo daño o riesgo de cualquier clase sufrido por el sitio o por la empresa a causa de lo expresado anteriormente será de cargo exclusivo del usuario.
    </p>

    <h3><strong>PRIVACIDAD:</strong></h3>
    <p class="text-justified">
      La Empresa respeta mucho la privacidad de los visitantes de sus sitios de Internet. Como política general, no se recopila automáticamente información personal de los visitantes de sus sitios. Sin embargo, durante la actividad habitual de los servidores de Internet, se registra determinada información no personal de los visitantes.
      Esta información se utiliza principalmente para proporcionar al visitante una mejor experiencia en línea. La información que se registra incluye: el tipo de navegador utilizado por el visitante, el tipo de sistema operativo utilizado por el visitante y el nombre de dominio del proveedor de servicios de Internet del visitante.
      Al contar con esta información, un visitante puede acceder automáticamente a las páginas Web optimizadas para la computadora de ese visitante en particular. Otros usos de esta información incluyen la revisión interna de la cantidad de visitantes de los sitios, pero sólo en forma conjunta y siempre que dicha información no pueda identificarlo personalmente.
      La Empresa sólo toma conocimiento de las direcciones de correo electrónico y otros datos que puedan identificar personalmente a los visitantes de este sitio cuando esta información se presenta voluntariamente.
    </p>

    <h3><strong>CÓMO UTILIZAMOS LA INFORMACIÓN PERSONAL PROPORCIONADA POR LOS VISITANTES:</strong></h3>
    <p class="text-justified">
      A través de nuestro sitio Web podemos recibir nombres, direcciones de correo electrónico, contenido de correos electrónicos, edades, información de facturación, información de contacto con los padres, información para envíos u otra información que los visitantes proporcionen voluntariamente. La Empresa puede conservar esta información, pero no venderla ni transferirla fuera de la compañía.
      En ocasiones, podemos utilizar direcciones de correo electrónico y otra información que pueda identificarlo personalmente, para ponernos en contacto con los visitantes que se comuniquen con nosotros, por ejemplo, para que reciban nuestros boletines electrónicos o para anunciarles que han ganado uno de nuestros concursos.
      Todo mensaje de correo electrónico enviado de esta forma incluirá instrucciones acerca de cómo cancelar la suscripción para no recibir futuros mensajes y cambiar la información contenida en el perfil de esa persona.
      Después podrá revisar o actualizar la información que lo identifica personalmente, en cualquier momento, escribiéndonos a contacto@happyland.cl Para proteger su información, también tomaremos medidas para verificar su identidad antes de conceder el acceso o de efectuar las correcciones. Aunque no es nuestra intención recopilar información de los niños, los padres pueden, después de proporcionar la correspondiente identificación como padres, solicitar la revisión, actualización o eliminación de la información de su propio hijo que pueda haberse presentado.
      Se han implementado procedimientos físicos, electrónicos y administrativos apropiados, a fin de proteger la integridad y seguridad de toda la información que obtenemos. Esta información se utiliza para comprender mejor de qué modo utilizan nuestros sitios los visitantes y para respaldar las transacciones que se realizan en ellos. Es posible que se proporcione a terceros una perspectiva general de estos datos (es decir, cantidad de usuarios, patrones de uso, etc.), con fines de marketing, publicidad u otros. Muchos sitios Web, incluido éste, colocan una «cookie» en los archivos de navegación de su computadora. La cookie en sí no contiene ninguna información que pueda identificarlo personalmente.
      Si bien gracias a la cookie podría ser posible que alguien relacionase el uso que le da un usuario a un sitio de HAPPYLAND M.R., con la información que el usuario ha proporcionado específicamente y con conocimiento, la Empresa no lo hace.
      Usamos preferencias para proporcionarle opciones con respecto a la manera en que interactúa con el servicio y la manera en que se utiliza su información. Por ejemplo, podemos usar la información presentada en nuestra área de juegos para activar los juegos o mostrar los puntajes más altos.
    </p>
  </article>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="primary">Cerrar</button>
</mat-dialog-actions>