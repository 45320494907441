import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ICardSettings } from 'src/app/interface/ICardSettings.interface';
import { PromotionModel } from 'src/app/model/promotion.model';
import { CardSettingsService } from 'src/app/services/card-settings/card-settings.service';
import { HelperService } from 'src/app/services/helper.service';
import { SpecialOfferTermDialogComponent } from '../special-offer-term-dialog/special-offer-term-dialog.component';
import { PromotionsService } from 'src/app/services/promotions/promotions.service';

@Component({
  selector: 'app-special-offer-dialog',
  templateUrl: './special-offer-dialog.component.html',
  styleUrls: ['./special-offer-dialog.component.scss'],
})
export class SpecialOfferDialogComponent {
  promotionUID: string;
  paymentImageURL: string;
  waiverImageURL: string;
  redirectToPromotion: boolean;
  accountNumber: number;

  latestPromotion: PromotionModel;
  cardSettings: ICardSettings;
  isChangingStatus: boolean = false;
  isSearchingPromotions: boolean = true;
  showErrorMessage: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      typeAdminPromotion: 'SEGMENTED' | 'REPURCHASE';
      promotionUID: string;
      promotionSource: PromotionModel;
      paymentImageURL: string;
      waiverImageURL: string;
      redirectToPromotion: boolean;
      accountNumber: number;
      cardSettings: ICardSettings;
    },
    private cardSettingsServices: CardSettingsService,
    private matDialog: MatDialog,
    private promotionService: PromotionsService,
    private router: Router,
    public dialogRef: MatDialogRef<SpecialOfferDialogComponent>,
    readonly helperService: HelperService
  ) {
    this.paymentImageURL = this.data.paymentImageURL;
    this.waiverImageURL = this.data.waiverImageURL;
    this.redirectToPromotion = this.data.redirectToPromotion;
    this.accountNumber = this.data.accountNumber;
    this.cardSettings = this.data.cardSettings;

    if (this.data.typeAdminPromotion === 'SEGMENTED') {
      this.requestPromotionInformation(this.data.promotionUID);
    } else {
      // console.log(`REPURCHASE - Loading preloaded promotion`);
      this.latestPromotion = this.data.promotionSource;

      // WORKAROUND: FORZANDO EL TIPO DE SEGÚN VISUALIZACIÓN
      this.latestPromotion.typeOfAdminPromotion = 'REPURCHASE';

      this.isSearchingPromotions = false;
    }
  }

  async requestPromotionInformation(promotionUID: string) {
    // console.log(`SEGMENTED - Loading promotion from ${promotionUID}`);
    const latestPromotion = await this.promotionService.searchPromotionByUID(promotionUID, null, []);
    this.latestPromotion = latestPromotion[0];

    // WORKAROUND: FORZANDO EL TIPO DE SEGÚN VISUALIZACIÓN
    this.latestPromotion.typeOfAdminPromotion = 'SEGMENTED';

    this.isSearchingPromotions = false;
  }

  async saveUserSettings() {
    try {
      this.isChangingStatus = true;

      this.cardSettings.showSpecialOffer = !this.cardSettings.showSpecialOffer;

      await this.cardSettingsServices.updateCardSettings(this.cardSettings);
    } catch (errCritical) {
    } finally {
      this.isChangingStatus = false;
    }
  }

  viewSpecialOfferTerm() {
    this.matDialog.open(SpecialOfferTermDialogComponent, {
      data: {
        message: this.latestPromotion.basesDescription,
      },
    });
  }

  requestRedeemOffer(): void {
    if (this.redirectToPromotion) {
      localStorage.setItem('promotion-data', JSON.stringify(this.latestPromotion));
      this.router.navigate([
        './payment-page',
        {
          card: this.accountNumber,
        },
      ]);

      this.dialogRef.close();
    } else {
      console.log('THIS OFFER IS ONLY VISUAL');
    }
  }
}
